html,
body {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  font-size: 1rem !important;
}

#app {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

#app > main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--bg);
}

@media screen and (min-width: 42em) {
  #app > main {
    padding-left: calc((100% - 40em) / 2);
    padding-right: calc((100% - 40em) / 2);
    background-color: transparent;
    max-width: none;
  }

  #app > main::before {
    content: " ";
    display: block;
    position: absolute;
    left: calc((100% - 40em) / 2 - 1em);
    right: calc((100% - 40em) / 2 - 1em);
    top: 0;
    bottom: 0;
    background-color: var(--bg);
    z-index: -1;
  }
}
